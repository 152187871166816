<template>
  <div>
    <v-container fluid>

      <v-overlay :value="overlay" opacity="0">
        <v-progress-circular
            indeterminate
            size="50"
            color="primary"
        ></v-progress-circular>
      </v-overlay>

      <v-row justify="center" align="center">
        <v-col>
          <Toolbar/>
        </v-col>
        <v-col class="text-right">

          <v-tooltip top color="primary">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" icon @click="[getUsers(),getCities(),getRoles()]" class="mr-2">
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
            </template>
            <span>Actualiser</span>
          </v-tooltip>

          <v-btn color="primary" class="rounded-lg" depressed
                 @click="addItem">
            <v-icon left>mdi-plus</v-icon>
            Ajouter un utilisateur
          </v-btn>
        </v-col>
      </v-row>

      <v-row v-if="loading && users.length === 0">
        <v-col cols="12" lg="3" v-for="(item,i) in 8" :key="i">
          <v-card class="shadow rounded-lg">
            <v-skeleton-loader type="list-item-avatar,article,actions"/>
          </v-card>
        </v-col>
      </v-row>


      <v-row v-if="!loading && users.length === 0">
        <v-col cols="12">
          <v-card flat class="transparent">
            <v-card-text class="text-center">
              <NoResult/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && users.length > 0">
        <v-col cols="12" lg="3" v-for="(user ,i) in users" :key="i">
          <v-card class="shadow rounded-lg">
            <v-card-text>
              <div class="text-center">
                <v-avatar size="60">
                  <v-img v-if="user.photo" :src="FILE_URL + user.photo"></v-img>
                  <v-img v-else :src="require('@/assets/avatar.png')"></v-img>
                </v-avatar>
                <h3 class="mt-3 mb-1">
                  {{ user.first_name }} {{ user.last_name }}
                </h3>
                <p>{{ user.email }}</p>
                <v-chip
                    @click="[
                     permissionsDialog = true,
                     D_permissions =  user.roles.length ?  user.roles[0].permissions.map(el=>el.name) : []
                   ]"
                    color="blue lighten-1" dark small>
                  {{ user.roles.length ? user.roles[0].name : '' }}
                  <v-icon right size="18">mdi-folder-cog-outline</v-icon>
                </v-chip>
              </div>
            </v-card-text>

              <v-divider/>

            <v-row justify="center" align="center" class="pl-2 pr-2" no-gutters>
              <v-col cols="4">
                <v-switch
                    @click="changeStatus(user.id, user.is_active)"
                    readonly color="blue" v-model="user.is_active"/>
              </v-col>
              <v-col class="text-right" cols="8">
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="deleteItem(user.id)">
                      <v-icon>mdi-delete-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Supprimer</span>
                </v-tooltip>
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="primary" @click="editItem(user)">
                      <v-icon>mdi-pencil-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Modifier</span>
                </v-tooltip>
                <v-tooltip top color="primary">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon color="primary"
                           @click="[
                            affectedCitiesDialog = true,
                            affectedCities =  user.cities.length ?  user.cities: []
                          ]">
                      <v-icon>mdi-map-marker-radius-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>Wilaya affectées</span>
                </v-tooltip>
              </v-col>
              <v-spacer/>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <!--Dialogs-->
      <v-dialog width="600" v-model="permissionsDialog" scrollable>
        <v-card>
          <v-card-title>
            Autorisations
            <v-spacer/>
            <v-icon @click="permissionsDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-row class="mt-3">
              <v-col cols="12" v-for="(permission ,key) in permissions" :key="key">
                <v-card outlined>
                  <v-card-title class="fs-15 pa-2">
                    {{ key }}
                    <v-spacer/>
                    <v-progress-circular
                        :rotate="360"
                        :size="45"
                        :width="5"
                        :value="totalSelected(permission) * 100 / permission.length"
                        color="blue">
                      {{ totalSelected(permission) }}/{{ permission.length }}
                    </v-progress-circular>
                  </v-card-title>
                  <v-divider/>
                  <div class="pa-2">
                    <v-chip filter v-for="(p ,i) in permission"
                            class="mb-2 mt-2 mr-2"
                            :key="i"
                            :value="p.name"
                            :class="D_permissions.includes(p.name) ? 'blue--text v-chip--active' : ''">
                      <v-icon left v-if="D_permissions.includes(p.name)">mdi-check</v-icon>
                      {{ p.name }}
                    </v-chip>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog width="600" v-model="affectedCitiesDialog" scrollable>
        <v-card>
          <v-card-title>
            Wilaya affectées
            <v-spacer/>
            <v-icon @click="affectedCitiesDialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text>
            <v-chip-group column>
              <v-chip v-for="(city,i) in affectedCities" :key="i" color="blue"
                      active-class="white--text"
                      dark>
                {{ city.name }}
              </v-chip>
            </v-chip-group>
          </v-card-text>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" width="600" persistent scrollable>
        <v-card>
          <v-card-title>
            {{ dialogTitle }}
            <v-spacer/>
            <v-icon @click="dialog = false">mdi-close</v-icon>
          </v-card-title>
          <v-divider/>
          <v-card-text class="pa-4">
            <userForm :roles="roles" :cities="cities" @close="dialog=false" @get-users="getUsers" ref="userForm"/>
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-container>
  </div>
</template>

<script>
import Toolbar from "./Toolbar";
import userForm from "./userForm";
import {HTTP} from "@/http-common";
import NoResult from "../../components/NoResult";

export default {
  components: {
    NoResult,
    Toolbar,
    userForm,
  },
  data() {
    return {
      FILE_URL: process.env.VUE_APP_FILE_URL,
      overlay: false,
      loading: false,
      id: '',
      users: [],
      roles: [],
      cities: [],
      dialogTitle: '',
      dialog: false,
      permissionsDialog: false,
      affectedCitiesDialog: false,
      affectedCities: [],
      D_permissions: [],
      permissions: []
    }
  },
  methods: {
    addItem() {
      this.dialogTitle = 'Ajouter un utilisateur'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.userForm.add()
      }, 100)
    },
    editItem(item) {
      this.dialogTitle = 'Modifier un utilisateur'
      this.dialog = true
      let _vm = this
      setTimeout(function () {
        _vm.$refs.userForm.edit(item)
      }, 100)
    },
    deleteItem(id) {
      this.id = id
      this.$confirm_dialog = true
    },
    getUsers() {
      this.users = []
      this.loading = true
      this.$Progress.start()
      HTTP.get('/users').then((res) => {
        this.loading = false
        this.$Progress.finish()
        this.users = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        this.loading = false
        console.log(err)
      })
    },
    getRoles() {
      this.roles = []
      this.$Progress.start()
      HTTP.get('/roles').then((res) => {
        this.$Progress.finish()
        this.roles = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    getCities() {
      this.cities = this.$store.state.all_cities
    },
    changeStatus(id, is_active) {
      this.$Progress.start()
      this.overlay = true
      let data = {
        id: id,
        is_active: !is_active,
      }
      HTTP.post('/users/change-status', data).then(() => {
        const i = this.users.findIndex(item => item.id === id)
        this.users[i].is_active = !is_active
        this.overlay = false
        this.$Progress.finish()
        this.$successMessage = 'Status changé avec succes !'
      }).catch(err => {
        console.log(err)
        this.$Progress.fail()
        this.overlay = false
      })
    },
    getPermissions() {
      this.permissions = []
      this.$Progress.start()
      HTTP.get('/roles/permissions').then((res) => {
        this.$Progress.finish()
        this.permissions = res.data.data
      }).catch(err => {
        this.$Progress.fail()
        console.log(err)
      })
    },
    totalSelected(permission) {
      let total = 0
      let _vm = this
      permission.map(function (el) {
        _vm.D_permissions.includes(el.name) ? total = total + 1 : false
      })
      return total
    }
  },
  created() {
    this.getPermissions()
    this.getUsers()
    this.getCities()
    this.getRoles()
  },
  watch: {
    '$is_confirm': function (el) {
      if (el) {
        this.$is_confirm = false
        this.overlay = true
        this.$Progress.start()
        HTTP.delete('/users/delete/' + this.id).then(() => {
          let i = this.users.findIndex(item => item.id === this.id)
          this.$delete(this.users, i)
          this.$successMessage = 'Cet utilisateur a été supprimé avec succès'
          this.overlay = false
          this.$Progress.finish()
        }).catch(err => {
          this.overlay = false
          this.$Progress.fail()
          console.log(err)
        })
      }
    },
  },
}
</script>

<style scoped>

</style>