<template>
  <div>
    <form enctype="multipart/form-data" @submit.prevent="save">
      <v-row>
        <v-col>
          <v-text-field v-model="form.first_name"
                        :error-messages="errors.first_name"
                        hide-details="auto"
                        label="Nom *" @input="errors && errors.first_name ? errors.first_name = '' : false"/>
        </v-col>
        <v-col>
          <v-text-field v-model="form.last_name"
                        :error-messages="errors.last_name"
                        hide-details="auto"
                        label="Prénom *" @input="errors && errors.last_name ? errors.last_name = '' : false"/>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-file-input ref="photo"
                        :error-messages="errors.photo"
                        accept="image/png,image/jpeg"
                        append-icon="mdi-image-outline"
                        hide-details="auto"
                        label="Photo *"
                        show-size
                        small-chips
                        @change="uploadFile"
                        @click="errors && errors.photo ? errors.photo = '' : false"/>
        </v-col>
        <v-col>
          <v-select v-model="form.role_id"
                    :error-messages="errors.role_id"
                    :items="roles"
                    hide-details="auto"
                    item-text="name"
                    item-value="id" label="Role *"
                    @input="errors && errors.role_id ? errors.role_id = '' : false"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-autocomplete v-model="form.cities"
                          :error-messages="errors.cities"
                          :items="cities"
                          chips
                          deletable-chips
                          hide-details="auto"
                          item-text="name"
                          item-value="name"
                          label="Affecter les wilayas *"
                          multiple small-chips
                          @input="errors && errors.cities ? errors.cities = '' : false">
            <template v-slot:prepend-item>
              <v-list-item ripple @click="toggle" @mousedown.prevent>
                <v-list-item-action>
                  <v-icon :color="form.cities.length > 0 ? 'primary' : ''">
                    {{ icon }}
                  </v-icon>
                </v-list-item-action>
                <v-list-item-content>
                  <v-list-item-title>
                    Sélectionner tout
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
              <v-divider class="mt-2"></v-divider>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field v-model="form.email"
                        :error-messages="errors.email"
                        label="Email *" @input="errors && errors.email ? errors.email = '' : false"/>
        </v-col>
        <v-col>
          <v-text-field v-model="form.password"
                        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                        :error-messages="errors.password"
                        :type="show ? 'text' : 'password'"
                        label="Mot de passe *"
                        @input="errors && errors.password ? errors.password = '' : false" @click:append="show = !show"/>
        </v-col>
      </v-row>
      <div class="d-flex justify-space-between mt-3">
        <v-spacer/>
        <v-btn :loading="btnLoading" class="rounded-lg" color="primary" depressed
               type="submit">
          <v-icon left>mdi-content-save</v-icon>
          Enregistrer
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>


import {HTTP} from "@/http-common";

export default {
    props: ['roles', 'cities'],
    data() {
        return {
            show: false,
            btnLoading: false,
            form: {
                id: '',
                first_name: '',
                last_name: '',
                photo: '',
                email: '',
                password: '',
                role_id: '',
                cities: [],
            },
            errors: {},
        }
    },
    methods: {
        uploadFile(file) {
            this.form.photo = file
        },
        edit(item) {
            this.form.id = item.id
            this.form.first_name = item.first_name
            this.form.last_name = item.last_name
            this.form.email = item.email
            this.form.password = ''
            this.form.role_id = item.roles.length > 0 ? item.roles[0].id : ''
            this.form.cities = item.cities.map(el => el.name)
            this.form.photo = ''
            this.$refs.photo.reset()
            this.errors = {}
        },
        add() {
            this.form.id = ''
            this.form.first_name = ''
            this.form.last_name = ''
            this.form.email = ''
            this.form.password = ''
            this.form.role_id = ''
            this.form.cities = []
            this.form.photo = ''
            this.$refs.photo.reset()
            this.errors = {}
        },
        save() {
            this.btnLoading = true
            this.$Progress.start()
            const url = this.form.id ? '/users/update' : '/users/store'
            let formData = new FormData();
            Object.keys(this.form).forEach((key) => {
                formData.append(key, this.form[key])
            })
            HTTP.post(url, formData).then(() => {
                this.$successMessage = 'Cet utilisateur a été ajouté avec succès'
                if (this.form.id) {
                    this.$emit('close')
                } else {
                    this.add()
                }
                this.$emit('get-users')
                this.btnLoading = false
                this.$Progress.finish()
            }).catch(err => {
                this.btnLoading = false
                this.$Progress.fail()
                this.errors = err.response.data.errors
                this.$errorMessage = "Une erreur ç'est produit veuillez le corriger."
                console.log(err)
            })
        },
        toggle() {
            if (this.form.cities.length === this.cities.length) {
                this.form.cities = []
            } else {
                this.form.cities = this.cities.map(el => el.name)
            }
        },
    },
    computed: {
        icon() {
            if (this.form.cities.length === this.cities.length) return 'mdi-close-box'
            if (this.form.cities.length > 0 && this.form.cities.length !== this.cities.length) return 'mdi-minus-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

}
</script>

<style scoped>

</style>